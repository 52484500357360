







































































.subscriber-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
